import store from '@/store';
import { Company } from '../../Companies/entities/company.entity';
import { Status } from '../../Status/entities/status.entity';
import SubstatusService from '../services/substatus.service';

export class Substatus {
  public id: string = '';

  public description: string = '';

  public active: boolean | number = true;

  public has_relationship: boolean | number = false;

  public companyId: string = '';

  public company: Company = new Company();

  public status: Status = new Status();

  constructor(data: any = {}) {
    this.id = data.id;
    this.description = data.description || '';
    this.companyId = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.company = new Company(data.company);
    this.status = new Status(data.status);
    this.active = data.active === false ? 0 : 1;
    this.has_relationship = data.has_relationship === true;
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  private format() {
    return {
      ...this,
      company_id: this.companyId,
      status_id: this.status.id,
    };
  }

  public save() {
    return SubstatusService.create(this.format());
  }

  public update() {
    return SubstatusService.update(this.format());
  }

  public delete() {
    return SubstatusService.delete(this.id);
  }
}
