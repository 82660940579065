
import { Component, Vue } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { Substatus } from '../../entities/substatus.entity';
import SubstatusService from '../../services/substatus.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class SubstatusListComponent extends Vue {
  private substatus: Substatus[] = [];

  private get headers(): any {
    if (this.$permissionAccess.group.isSuperAdmin) {
      return [
        { text: 'Descrição', value: 'description', width: '20%' },
        { text: 'Status do Pedido', value: 'status.description', width: '30%' },
        { text: 'Empresa', value: 'company.name', width: '20%' },
        { text: 'Ativo?', value: 'activeText', width: '10%' },
        {
          text: 'Ações',
          width: '10%',
          align: 'center',
          value: 'actions',
        },
      ];
    }
    return [
      { text: 'Descrição', value: 'description', width: '40%' },
      { text: 'Status do Pedido', value: 'status.description', width: '40%' },
      { text: 'Ativo?', value: 'activeText', width: '10%' },
      {
        text: 'Ações',
        width: '10%',
        align: 'center',
        value: 'actions',
      },
    ];
  }

  private loading: boolean = false;

  private goToFormEdit(substatus: Substatus) {
    this.$router.push({
      name: 'sub_status-edit',
      params: {
        id: substatus.id,
      },
    });
  }

  private goToFormCreate() {
    this.$router.push({
      name: 'sub_status-create',
    });
  }

  private async getStatuses() {
    this.loading = true;
    const substatus = await SubstatusService.getAllSubstatusPaginate().finally(() => {
      this.loading = false;
    });
    this.substatus = substatus.map((substatusItem: Substatus) => new Substatus(substatusItem));
  }

  public async backPagination() {
    if (!SubstatusService.simplePaginationService.prevLink) return;
    const substatus = await SubstatusService.simplePaginationService.prev();
    this.substatus = substatus.map((substatusItem: Substatus) => new Substatus(substatusItem));
  }

  public async nextPagination() {
    if (!SubstatusService.simplePaginationService.nextLink) return;
    const substatus = await SubstatusService.simplePaginationService.next();
    this.substatus = substatus.map((substatusItem: Substatus) => new Substatus(substatusItem));
  }

  public async goToFirst() {
    if (!SubstatusService.simplePaginationService.firstLink) return;
    const substatus = await SubstatusService.simplePaginationService.goToFirst();
    this.substatus = substatus.map((substatusItem: Substatus) => new Substatus(substatusItem));
  }

  private async created() {
    this.getStatuses();
  }
}
