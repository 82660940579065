import { AxiosResponse } from 'axios';
import http from '@/services/http.service';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import { Substatus } from '../entities/substatus.entity';

class SubstatusService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: Substatus) {
    return http.post('/sub-status', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: Substatus) {
    return http.put(`/sub-status/${payload.id}`, payload).then(({ data }: AxiosResponse) => data);
  }

  public delete(statusId: string) {
    return http.delete(`/sub-status/${statusId}`).then(({ data }: AxiosResponse) => data);
  }

  public getAllSubstatus() {
    return http.get('/sub-status').then(({ data }: AxiosResponse) => data.data);
  }

  public getAllSubstatusPaginate() {
    return http
      .get('/sub-status', {
        params: {
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }

  public getStatusById(id: string) {
    return http.get(`/sub-status/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public getSubStatusByCompanyId(companyId: string) {
    return http
      .get('/sub-status', {
        params: {
          company_id: companyId,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getSubstatusByStatusItem(status_id: string, companyId: string) {
    return http
      .get('/sub-status', {
        params: {
          status_id,
          company_id: companyId,
          active: 1,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }
}
const simplePaginationService = new SimplePagination();
export default new SubstatusService(simplePaginationService);
