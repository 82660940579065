import { AxiosResponse } from 'axios';
import { Timeline } from '@/modules/Clients/components/form/entities/timeline.entity';
import http from '@/services/http.service';
import moment from '@/services/moment/moment.service';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import { Status } from '../entities/status.entity';

namespace Reorder {
  export type ReorderStatus = {
    id: string;
    order_number: number;
  };

  export type Params = {
    company_id: string;
    statuses: ReorderStatus[];
  };
}

class StatusService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: Status) {
    return http.post('/status', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: Status) {
    return http.put(`/status/${payload.id}`, payload).then(({ data }: AxiosResponse) => data);
  }

  public delete(statusId: string) {
    return http.delete(`/status/${statusId}`).then(({ data }: AxiosResponse) => data);
  }

  public getAllStatus() {
    return http.get('/status').then(({ data }: AxiosResponse) => data.data);
  }

  public getAllStatusPaginate() {
    return http
      .get('/status', {
        params: {
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }

  public getStatusById(id: string) {
    return http.get(`/status/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public getStatusByCompanyId(companyId: string) {
    return http
      .get('/status', {
        params: {
          company_id: companyId,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getStatusByStatusItem(status: string, companyId: string) {
    return http
      .get('/status', {
        params: {
          status,
          company_id: companyId,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public reorderStatus(params: Reorder.Params) {
    return http.patch('/status/reorder', params).then(({ data }: AxiosResponse) => data.data);
  }

  changeDate(order_id: string, event: Timeline) {
    return http
      .post('/order/change-status-date', {
        order_id,
        order_status_id: event.order_status_id,
        created_at: moment(event.created_at).format('YYYY-MM-DD HH:mm'),
      })
      .then(({ data }: AxiosResponse) => data.data);
  }
}

const simplePaginationService = new SimplePagination();
export default new StatusService(simplePaginationService);
