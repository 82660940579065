import store from '@/store';
import StatusService from '../services/status.service';

export class Status {
  public id: string = '';

  public description: string = '';

  public type: string = '';

  public active: boolean | number = true;

  public disabled?: boolean = false;

  public order_number: number = 0;

  public has_relationship: boolean | number = false;

  public company_name: string = '';

  public companyId: string = '';

  public color: string = '';

  public system: boolean = false;

  constructor(data: any = {}) {
    this.id = data.id;
    this.description = data.description || '';
    this.company_name = data.company_name || '';
    this.order_number = data.order_number || '';
    this.type = data.type || '';
    this.disabled = data.disabled;
    this.color = data.color || 'pink ';
    this.system = data.system === true;
    this.companyId = data.company_id || store.getters.user.company_id;
    this.company_name = data.company_name || '';
    this.active = data.active === false ? 0 : 1;
    this.has_relationship = data.has_relationship === true;
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  private format() {
    return {
      ...this,
      company_id: this.companyId,
    };
  }

  public save() {
    return StatusService.create(this.format());
  }

  public update() {
    return StatusService.update(this.format());
  }

  public delete() {
    return StatusService.delete(this.id);
  }
}
